import React, { useState, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import "./css/main.css";
import "animate.css/animate.min.css";
import CookieConsent from "react-cookie-consent";

import loadable from "@loadable/component";

import { MenueContext } from "./components/helper/Content";

const Menue = loadable(() => import("./components/nav/menue"));
const CTAContent = loadable(() => import("./components/elements/CTA/ctaContent"));
const Overlay = loadable(() => import("./components/elements/overlay"));
const Footer = loadable(() => import("./components/Footer/footer"));
const Nav = loadable(() => import("./components/nav/navbarNew"));
const AnimatedRoutes = loadable(() => import("./AnimatedRoutes"));

function App() {
    const [menueOpen, setMenueOpen] = useState(false);
    const [ctaOpen, setCtaOpen] = useState(false);
    const [noHero, setNoHero] = useState(false);
    const [noLogo, setNoLogo] = useState(false);

    return (
        <MenueContext.Provider
            value={{ menueOpen, setMenueOpen, ctaOpen, setCtaOpen, noHero, setNoHero, noLogo, setNoLogo }}
            className="App"
        >
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2470ab" }}
                buttonStyle={{ color: "#fff", fontSize: "13px" }}
                expires={150}
            >
                Wir verwenden Cookies, um Ihnen die bestmögliche Nutzungserfahrung zu bieten.
                <br />
                <span style={{ fontSize: "10px" }}>
                    Sie stimmen der Nutzung von Cookies und unseren Datenschutzbestimmungen zu
                </span>
            </CookieConsent>
            <Router>
                {menueOpen && <Overlay></Overlay>}
                {ctaOpen && <Overlay></Overlay>}
                <Helmet>
                    <title>Rosenstengel Immobilien</title>
                    <meta name="title" content="Rosenstengel Immobilien" />
                    <meta
                        name="description"
                        content="Als ortsansässiges und Inhaber geführtes Immobilienmakler Unternehmen konnten wir uns in den letzten Jahrzehnten in unserer Region einen hohen Bekanntheitsgrad und eine große Anerkennung als seriöses sowie kompetentes Maklerunternehmen erarbeiten."
                    />
                    <meta
                        name="keywords"
                        content="Rosenstengel, Immobilien, Makler, Dreieich, Deutschland, Haus,  Häuser, Wohnungen, Grundstücke, Neubau, Neubauten, wohnen, Heike, "
                    />
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta name="author" content="Heike Rosenstengel" />
                </Helmet>
                <Nav standard={!noLogo}></Nav>

                <Menue></Menue>
                <CTAContent></CTAContent>
                <Suspense fallback={<div>LOADING</div>}>
                    <AnimatedRoutes></AnimatedRoutes>
                </Suspense>
                <Footer></Footer>
            </Router>
        </MenueContext.Provider>
    );
}

export default App;
